/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import {
	PaperPorts,
	Environment,
	QuoteType,
	tQuoteValueType,
	NameVisibility,
	tNameVisibility,
	PaperInstruments,
	Role,
	tRole,
} from 'src/constants/contract';
import { getCounterparties } from 'src/_api';
import { toPath } from 'src/_helpers';
import { tEnvironmentAlternative } from 'src/constants/contract';
import { mapCounterparties } from 'src/_helpers/mapCounterparties';
import { DrawerContextKeys, useDrawerContext } from 'src/components/Drawer/DrawerContext';
import { isCounterpartyAContact } from 'src/_helpers/isCounterpartyAContact';
import { useWatchPaperFields } from '../../useWatchPaperFields';
import { usePaperOrderContext } from '../../CreatePaperOrderDrawerContent';
import { getDefaultValidity } from '../../helpers/getDefaultValidity';

export const useContractAndPricingSection = ({ path }) => {
	const { t } = useTranslation();

	const { setValue, unregister } = useFormContext();

	const { selectedPreset: selectedProductPresetValue } = usePaperOrderContext();

	const {
		numberOfDeliveryMonthsValue,
		runsValue,
		environmentValue,
		counterpartiesValue,
		isEditing,
		validityChangedValue,
		orderTypeValue,
		instrumentValue,
	} = useWatchPaperFields(path);

	const isOTC = environmentValue === Environment.OTC;

	const {
		[DrawerContextKeys.createDrawer]: { otcRecipients, setOtcRecipients },
	} = useDrawerContext();

	/* Order type */
	const orderTypeOptions = useMemo(
		() =>
			Object.values(QuoteType).map(value => ({
				text: tQuoteValueType(t, value),
				key: value,
				value,
			})),
		[t]
	);

	/**
	 * When order type changes and the validity value wasn't changed, we set 5 minutes for Firm and 24 hours for Indicative
	 */
	useEffect(() => {
		if (validityChangedValue) {
			return;
		}

		setValue('validity', getDefaultValidity(orderTypeValue));
	}, [orderTypeValue, setValue, validityChangedValue]);

	/* Visibility */
	const nameVisibilityOptions = Object.values(NameVisibility).map(environment => ({
		value: environment,
		text: tNameVisibility(t, environment),
	}));

	/* Environment */
	const environmentOptions = Object.values(Environment).map(environment => ({
		value: environment,
		text: tEnvironmentAlternative(t, environment),
	}));

	useEffect(() => {
		if (selectedProductPresetValue && !environmentValue) {
			setValue(toPath(path, 'environment'), Environment.Exchange);
		}
	}, [selectedProductPresetValue, setValue, environmentValue, path]);

	/* Counterparties */
	const mapUser = user => ({
		_key: user.contact_user_id,
		first_name: user.name,
		last_name: '',
		avatar_color: user.avatar_color,
		status: user.status,
		name: user.name,
		company_id: user.company_id,
		company: {
			_key: user.company_id,
			name: user.company_name,
			country_code: user.company_country_code,
			city: user.city,
		},
	});

	const { data: counterpartiesOptions, isLoading: isLoadingCounterparties } = useQuery(
		['order-otc-list'],
		() => getCounterparties(),
		{
			enabled: isOTC,
			cacheTime: 0,
			staleTime: 0,
			select: ({ contacts, groups }) => {
				return {
					...mapCounterparties(t, contacts, groups),
					contacts,
					groups,
				};
			},
		}
	);

	useEffect(() => {
		if (counterpartiesOptions && counterpartiesValue.length) {
			const fullList = [];
			counterpartiesValue.forEach(item => {
				if (item.users) {
					item.users.forEach(userId => {
						const userRecord = counterpartiesOptions.contacts.find(
							contact => contact.contact_user_id === userId
						);
						if (userRecord) {
							fullList.push(mapUser(userRecord));
						}
					});
				} else {
					const userRecord = counterpartiesOptions.contacts.find(contact => {
						const id = typeof item === 'string' ? item : item.id;
						return contact.contact_user_id === id;
					});
					if (userRecord) {
						fullList.push(mapUser(userRecord));
					} else {
						const groupRecord = counterpartiesOptions.groups.find(
							group => group._key === item
						);
						if (groupRecord && groupRecord.users) {
							groupRecord.users.forEach(userRecord => {
								fullList.push(mapUser(userRecord));
							});
						}
					}
				}
			});
			setValue(toPath(path, 'fullContactsList'), fullList);
		}
	}, [counterpartiesOptions, counterpartiesValue, setValue, path]);

	useEffect(() => {
		if (environmentValue === Environment.Exchange) {
			unregister(toPath(path, 'counterparties'));
			setValue(toPath(path, 'fullContactsList', []));
		}
	}, [environmentValue, setValue, path, unregister]);

	useEffect(() => {
		if (otcRecipients?.length) {
			setValue(toPath(path, 'environment'), Environment.OTC);
			setValue(toPath(path, 'counterparties'), otcRecipients);
			setOtcRecipients([]); // clear the list so the effect will not be launched when getting back from preview
		}
	}, [otcRecipients, path, setValue, setOtcRecipients]);

	useEffect(() => {
		if (!counterpartiesOptions || !counterpartiesValue || counterpartiesValue.length === 0) {
			return;
		}

		const approvedCounterparties = counterpartiesValue.filter(userId =>
			isCounterpartyAContact(
				userId,
				counterpartiesOptions.contacts,
				counterpartiesOptions.groups
			)
		);

		if (approvedCounterparties.length === 0) {
			setValue(toPath(path, 'counterparties'), undefined);
			setValue('fullContactsList', []);
		}
	}, [counterpartiesOptions, counterpartiesValue, path, setValue]);

	/** ROLE */
	const roleOptions = Object.values(Role).map(role => ({
		value: role,
		text: tRole(t, role),
	}));

	/* Meta */
	const hasRunsSupport = selectedProductPresetValue?.runs;

	const isFOBParanagua = selectedProductPresetValue?.loading_port?._key === PaperPorts.PARANAGUA;

	const shouldDisplayTotalQuantityForPKPG =
		hasRunsSupport && runsValue && (runsValue > 1 || numberOfDeliveryMonthsValue > 1);
	const shouldDisplayTotalQuantityForFOBParanagua =
		instrumentValue === PaperInstruments.Outright &&
		isFOBParanagua &&
		numberOfDeliveryMonthsValue > 1;

	const shouldDisplayTotalQuantity =
		shouldDisplayTotalQuantityForPKPG || shouldDisplayTotalQuantityForFOBParanagua;

	return {
		orderType: { options: orderTypeOptions, ready: true },
		nameVisibility: { options: nameVisibilityOptions, ready: true },
		environment: {
			options: environmentOptions,
			ready: true,
			defaultValue: environmentValue,
			disabled: isEditing,
		},
		counterparties: {
			visible: !isEditing && isOTC,
			options: counterpartiesOptions?.listOptions,
			groups: counterpartiesOptions?.groupOptions,
			ready: !isLoadingCounterparties,
		},
		shouldDisplayTotalQuantity,
		shouldShowReadOnlyCounterparties: isEditing && isOTC,
		role: { options: roleOptions, ready: true },
	};
};
