/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
	createPaperOrderSchema,
	mapCounterFormToCounterAPI,
} from 'src/components/CreateOrderDrawer/paper/model';
import { useForm } from 'react-hook-form';
import { DrawerContextKeys, useDrawerContext } from 'src/components/Drawer/DrawerContext';
import { mapLastCounterToFormData, mapPreviewDataToCounterView } from './model';
import { HeaderType } from 'src/components/Drawer/constants';
import { mapDataToApi } from 'src/components/CreateOrderDrawer/paper/model';
import { getUser, getUserId } from 'src/_store/selectors';
import { useMutation, useQueryClient } from 'react-query';
import { createCounter, trackAnalytics, isDevEnvironment } from 'src/_api';
import { getFormErrorMessage } from 'src/_helpers/getFormErrorMessage';
import { FooterTabs, isFooterTabActive } from 'src/_helpers/session';

const resolver = yupResolver(createPaperOrderSchema, {
	abortEarly: false,
});

const paperCounterResolver = async (data, ctx, options) => {
	return resolver(
		data,
		{
			...ctx,
			runsRequired: data.runsRequired,
			instrument: data.instrument,
			paperCounter: true,
			orderTypeChanged: false,
		},
		options
	);
};

export const usePaperCounterForm = ({ order, lastCounter, negotiationId, setCounterForm }) => {
	const { t } = useTranslation();
	const user = useSelector(getUser);
	const userId = useSelector(getUserId);
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	const initialState = useMemo(() => mapLastCounterToFormData(lastCounter), [lastCounter]);

	const {
		[DrawerContextKeys.viewDrawer]: {
			setTemporaryHeader,
			counterPreviewData,
			setCounterPreviewData,
		},
	} = useDrawerContext();

	const formMethods = useForm({
		resolver: paperCounterResolver,
		defaultValues: {
			...initialState,
		},
	});

	const { mutate: markCounterAsRead } = useMutation(trackAnalytics, {
		onError: error => {
			console.log('Error on setting analytics: ', error);
		},
	});

	const { mutate: counter, isLoading: isCountering } = useMutation(createCounter, {
		onSuccess: async ({ _key, environment, order_id, first_counter_id, error }) => {
			if (_key) {
				markCounterAsRead({ counter_order_id: _key });

				await queryClient.invalidateQueries(['order', order._key]);

				if (isFooterTabActive(FooterTabs.MyNegotiations)) {
					await queryClient.invalidateQueries(['my-negotiations']);
				}

				await queryClient.invalidateQueries(['my-unseen-negotiations']);

				setCounterForm(false, null);

				if (!order.is_my_order) {
					navigate(
						`/?orderEnvironment=${environment}&orderId=${order_id}&negotiationId=${first_counter_id}`
					);
				}
			} else if (error) {
				setTemporaryHeader({
					message: t('counter_creation_failed'),
					type: HeaderType.Warning,
				});
			}
		},
		onError: () => {
			setTemporaryHeader({
				message: t('counter_creation_failed'),
				type: HeaderType.Warning,
			});
		},
	});

	let counterparty = null;

	if (!!lastCounter) {
		const { counter_order_user, counter_order_user_company } = lastCounter;

		counterparty = { ...counter_order_user, company: { ...counter_order_user_company } };
	}

	const hasStartedNegotiation = !!lastCounter?.first_counter_id;

	const submitCounter = useCallback(() => {
		const data = formMethods.getValues();
		const mappedFormData = mapCounterFormToCounterAPI(
			data,
			order,
			userId,
			negotiationId,
			lastCounter
		);

		counter({
			...mappedFormData,
			order_id: order._key,
			order_user_id: order.user_id,
			counter_order_user_id: counterparty?._key || userId,
			first_counter_id: negotiationId,
			...(!hasStartedNegotiation && { version: order.version }),
		});
	}, [
		counter,
		lastCounter,
		counterparty?._key,
		formMethods,
		negotiationId,
		order,
		userId,
		hasStartedNegotiation,
	]);

	const onSubmit = data => {
		const body = mapDataToApi(data);

		if (counterPreviewData) {
			submitCounter();
		} else {
			setCounterPreviewData(mapPreviewDataToCounterView(body, data, user, lastCounter));
		}
	};

	const onSubmitError = errors => {
		if (isDevEnvironment) {
			console.error(errors);
		}
		const errorMessage = getFormErrorMessage(errors, t);
		setTemporaryHeader({ type: HeaderType.Warning, message: errorMessage });
	};

	return {
		initialState,
		formMethods,
		onSubmit: formMethods.handleSubmit(onSubmit, onSubmitError),
		isCountering,
		counterPreviewData,
	};
};
