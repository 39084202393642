/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useMemo, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { toPath } from 'src/_helpers';
import { OrderType, tLegQuote, Currency, PriceUnit } from 'src/constants/contract';
import { useWatchPaperFields } from '../../useWatchPaperFields';
import { isNextMonthOrAfter, toUTCUnix } from 'src/_helpers/date';
import { usePaperOrderContext } from '../../CreatePaperOrderDrawerContent';
import { genFutureContractMonths } from 'src/containers/Order/PhysicalOrder/Groups/Pricing/FuturesContractDate';
import { calculateClosestFuturesMonth } from '../../helpers/calculateClosestFuturesMonth';

export const useCalendarSpreadSection = ({ path }) => {
	const { t } = useTranslation();

	const { setValue } = useFormContext();
	const { selectedPreset: selectedProductPresetValue } = usePaperOrderContext();

	const {
		typeValue,
		firstLegMonthValue,
		secondLegMonthValue,
		firstLegFuturesMonthChangedValue,
		secondLegFuturesMonthChangedValue,
		availableInstruments,
		instrumentValue,
		priceTypeValue,
		volumeValue,
		isEditing,
		isCopying,
	} = useWatchPaperFields(path);

	/** 1st leg quote */
	const legQuoteOptions = useMemo(
		() =>
			Object.values(OrderType).map(value => ({
				text: tLegQuote(t, value),
				key: value,
				value,
			})),
		[t]
	);

	/** Set default 1st leg quote when not defined */
	useEffect(() => {
		if (!typeValue) {
			setValue(toPath(path, 'type'), OrderType.Buy);
		}
	}, [typeValue, path, setValue]);

	/** Set 2nd leg quote as opposite to 1st leg quote */
	useEffect(() => {
		if (typeValue) {
			setValue(
				toPath(path, 'secondLegQuote'),
				typeValue === OrderType.Buy ? OrderType.Sell : OrderType.Buy
			);
		}
	}, [typeValue, path, setValue]);

	/** 2st leg month should always be after 1st leg month */
	const onFirstLegMonthChange = value => {
		if (
			secondLegMonthValue &&
			!isNextMonthOrAfter(secondLegMonthValue.startDate, value.startDate)
		) {
			setValue(toPath(path, 'secondLegMonth'), { format: 'months' });
		}
	};

	/** check if all priceTypeOptions are available for the currently selected instrument */
	useEffect(() => {
		if (instrumentValue && priceTypeValue) {
			if (
				availableInstruments[instrumentValue] &&
				!availableInstruments[instrumentValue].includes(priceTypeValue)
			) {
				setValue(toPath(path, 'priceType'), availableInstruments[instrumentValue][0]);
			}
		}
	}, [availableInstruments, instrumentValue, path, priceTypeValue, setValue]);

	/* Futures months */
	const selectedFuturesContractMonths = useMemo(() => {
		if (!selectedProductPresetValue) {
			return [];
		}

		return (
			selectedProductPresetValue?.product?.futures_contracts?.find(
				fc => fc.futures_contract_id === priceTypeValue
			)?.future_months || []
		);
	}, [priceTypeValue, selectedProductPresetValue]);

	const futuresMonthOptions = useMemo(() => {
		return genFutureContractMonths(selectedFuturesContractMonths).map(value => ({
			key: value.valueOf(),
			text: moment(value).format('MMM YYYY'),
			value: toUTCUnix(value),
		}));
	}, [selectedFuturesContractMonths]);

	/** 1st leg futures month */
	useEffect(() => {
		if (!firstLegMonthValue || firstLegFuturesMonthChangedValue || isEditing || isCopying) {
			return;
		}

		const closestFuturesMonth = calculateClosestFuturesMonth(
			firstLegMonthValue,
			futuresMonthOptions
		);

		setValue(toPath(path, 'firstLegFuturesMonth'), closestFuturesMonth?.value || null);
	}, [
		firstLegFuturesMonthChangedValue,
		firstLegMonthValue,
		futuresMonthOptions,
		isCopying,
		isEditing,
		path,
		setValue,
	]);

	/** 2nd leg futures month */
	useEffect(() => {
		if (!secondLegMonthValue || secondLegFuturesMonthChangedValue || isEditing || isCopying) {
			return;
		}

		const closestFuturesMonth = calculateClosestFuturesMonth(
			secondLegMonthValue,
			futuresMonthOptions
		);

		setValue(toPath(path, 'secondLegFuturesMonth'), closestFuturesMonth?.value || null);
	}, [
		secondLegFuturesMonthChangedValue,
		secondLegMonthValue,
		futuresMonthOptions,
		isCopying,
		isEditing,
		path,
		setValue,
	]);

	useEffect(() => {
		setValue(toPath(path, 'currencyUnit'), `${Currency.USD}/${PriceUnit.Bushel}`);
	}, [path, setValue]);

	useEffect(() => {
		if (volumeValue === '') {
			setValue(toPath(path, 'volume'), null, { shouldValidate: true });
		}
	}, [path, setValue, volumeValue]);

	return {
		firstLegOrderType: { options: legQuoteOptions, ready: true },
		secondLegQuote: { options: legQuoteOptions, ready: false },
		firstLegMonth: { onChange: onFirstLegMonthChange },
		firstLegFuturesMonth: { options: futuresMonthOptions },
		secondLegFuturesMonth: { options: futuresMonthOptions },
		currency: Currency.USD,
		unit: PriceUnit.Bushel,
	};
};
