/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { Market } from 'src/constants/contract';
import {
	changeablePhysicalCounterFields,
	changeablePaperCounterFields,
} from '../Counter/constants';

export const getCounterChanges = (previousCounter, currentCounter) => {
	if (!previousCounter || !currentCounter) {
		return;
	}

	if (!!currentCounter.trade_id) {
		return {};
	}

	const changedFields = getChangesBetweenCounters(currentCounter, previousCounter);

	return changedFields;
};

export const getChangesBetweenCounters = (currentCounter, previousCounter) => {
	const counterFields =
		currentCounter.market === Market.Physical
			? changeablePhysicalCounterFields
			: changeablePaperCounterFields;
	return counterFields.reduce((res, fieldName) => {
		if (fieldName.includes('.')) {
			const [name, prop] = fieldName.split('.');

			const isCurrentCounterArrayLonger =
				currentCounter[name]?.length > previousCounter[name]?.length;
			const firstArray = isCurrentCounterArrayLonger ? currentCounter : previousCounter;
			const secondArray = isCurrentCounterArrayLonger ? previousCounter : currentCounter;

			(firstArray[name] || []).forEach((field, index) => {
				// eslint-disable-next-line eqeqeq
				if (field[prop] != secondArray[name]?.[index]?.[prop]) {
					res[`${name}[${index}].${prop}`] = true;
				}
			});
		} else if (
			currentCounter[fieldName] instanceof Date ||
			previousCounter[fieldName] instanceof Date
		) {
			const currentField = currentCounter[fieldName];
			const currentDate = currentField
				? currentField instanceof Date
					? currentField.toISOString()
					: new Date(currentField).toISOString()
				: null;
			const previousField = previousCounter[fieldName];
			const previousDate = previousField
				? previousField instanceof Date
					? previousField.toISOString()
					: new Date(previousField).toISOString()
				: null;

			if (currentDate !== previousDate) {
				res[fieldName] = true;
			}
		} else if (
			(typeof currentCounter[fieldName] === 'object' && currentCounter[fieldName] !== null) ||
			(typeof previousCounter[fieldName] === 'object' && previousCounter[fieldName] !== null)
		) {
			if (currentCounter[fieldName]?._key !== previousCounter[fieldName]?._key) {
				res[fieldName] = true;
			}
		} else if (fieldName === 'order_owner_principal_id') {
			if (
				currentCounter[fieldName] &&
				currentCounter[fieldName] !== previousCounter[fieldName]
			) {
				res[fieldName] = true;
			}
		} else if (fieldName === 'firstLegPrice') {
			if (parseFloat(currentCounter[fieldName]) !== parseFloat(previousCounter[fieldName])) {
				res[fieldName] = true;
			}
		} else if (fieldName === 'runs' || fieldName === 'volume') {
			let current = currentCounter[fieldName];
			let previous = previousCounter[fieldName];

			if (isNaN(current) || current === undefined || current === 0 || current === '') {
				current = null;
			}

			if (isNaN(previous) || previous === undefined || previous === 0 || previous === '') {
				previous = null;
			}

			// eslint-disable-next-line eqeqeq
			if (current != previous) {
				res[fieldName] = true;
			}
			// eslint-disable-next-line eqeqeq
		} else if (currentCounter[fieldName] != previousCounter[fieldName]) {
			res[fieldName] = true;
		}

		return res;
	}, {});
};
