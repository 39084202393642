/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import qs from 'querystring';
import { getApiUrl } from 'src/_api/getApiUrl';
import { ROUTES } from 'src/constants/routes';
import { escapeHref } from 'src/_helpers/escapeHref';

export const API_URL = getApiUrl(document.location);

export const isDevEnvironment = API_URL.includes('dev') || API_URL.includes('cloud');

export let CSRFToken;

export const setCSRFToken = t => {
	CSRFToken = t;
};

export const withCSRFTokenHeader = headers => {
	return {
		...headers,
		...(CSRFToken && { 'X-CSRF-Token': CSRFToken }),
	};
};

export async function fetchFromExchangeApi(url, { query, mapStatusToResult, body, ...params }) {
	const requestUrl = `${API_URL}/v1/${url}${query ? `?${qs.encode(query)}` : ''}`;

	const response = await fetch(requestUrl, {
		credentials: 'include',
		...(body && { body: formatBody(body) }),
		...params,
		headers: withCSRFTokenHeader(params.headers),
	});

	if (response?.status === 401) {
		document.location.href = escapeHref(ROUTES.login);
		return;
	}

	const maybeResultFromStatus = mapStatusToResult?.(response.status);

	if (maybeResultFromStatus || maybeResultFromStatus === null) {
		return maybeResultFromStatus;
	}

	return parseResponse(response);
}

export async function fetchFromServerSide(url, { query, mapStatusToResult, body, ...params }) {
	const requestUrl = `/api/${url}${query ? `?${qs.encode(query)}` : ''}`;

	const response = await fetch(requestUrl, {
		credentials: 'include',
		...(body && { body: formatBody(body) }),
		...params,
		headers: withCSRFTokenHeader(params.headers),
	});

	if (response?.status === 401) {
		document.location.href = escapeHref(ROUTES.login);
		return;
	}

	const maybeResultFromStatus = mapStatusToResult?.(response.status);

	if (maybeResultFromStatus || maybeResultFromStatus === null) {
		return maybeResultFromStatus;
	}

	return parseResponse(response);
}

// FOR RUNNING LOCALLY AND TESTING VALUES AGAINST THE MOCK.VOSBOR.DEV ENDPOINT
export async function fetchFromMockApi(url, { query, mapStatusToResult, body, ...params }) {
	const requestUrl = `https://mock.vosbor.dev/v1/${url}${query ? `?${qs.encode(query)}` : ''}`;

	const response = await fetch(requestUrl, {
		...(body && { body: formatBody(body) }),
		...params,
		headers: withCSRFTokenHeader(params.headers),
	});

	if (response?.status === 401) {
		document.location.href = escapeHref(ROUTES.login);
		return;
	}

	const maybeResultFromStatus = mapStatusToResult?.(response.status);

	if (maybeResultFromStatus || maybeResultFromStatus === null) {
		return maybeResultFromStatus;
	}

	return parseResponse(response);
}

const formatBody = body => {
	if (body instanceof FormData) {
		return body;
	}

	return JSON.stringify(body);
};

export const statusMap = {
	200: 'OK',
	400: 'Bad request',
	401: 'Unauthorized',
	402: 'Payment required, account was not approved',
	403: 'Forbidden',
	404: 'Not found',
	418: 'ValidationError',
	500: 'Internal Server Error',
	503: 'Service Unavailable',
};

const parseResponse = async resp => {
	let res;

	try {
		res = await resp.json();
	} catch (e) {
		res = {
			error: statusMap[resp.status],
			message: res?.message || res?.error || undefined,
		};

		if (resp.status !== 200 && resp.status !== 204) {
			console.log('HTTP Request Error', res);

			// react-query relies on error thrown for setting isError flag
			throw new Error(e);
		}
	}

	return res;
};

export function map404ToEmptyList(status) {
	if (status === 404) {
		return [];
	}
}

export function map404ToEmptyObject(status) {
	if (status === 404) {
		return {};
	}
}

export function map404ToNull(status) {
	if (status === 404) {
		return null;
	}
}

export const map204ToNull = status => {
	if (status === 204) {
		return null;
	}
};

export const mapFalsyResponseToEmptyArray = res => res || [];
