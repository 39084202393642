/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { MaterialSelectInput } from 'src/designSystem/Form/MaterialInput/MaterialSelectInput';
import { MaterialNumberInput } from 'src/designSystem/Form/MaterialInput/MaterialNumberInput';
import { tCurrency, QuoteType } from 'src/constants/contract';
import { useCalendarSpreadFields } from './useCalendarSpreadFields';
import { FieldContainer } from 'src/components/CreateOrderDrawer/components/FoldableSection/styled';
import { useWatchPaperCounterFields } from '../../../helpers/paper/useWatchPaperCounterFields';
import { toPath } from 'src/_helpers';
import * as Styled from 'src/components/CreateOrderDrawer/paper/sections/ContractAndPricingSection/styled';

export const CalendarSpreadForm = ({ path, lastCounter }) => {
	const { t } = useTranslation();

	const { setValue, trigger, formState } = useFormContext();

	const {
		orderType,
		currency,
		unit,
		price,
		firstLegPrice,
		volume,
		principal,
	} = useCalendarSpreadFields({
		path,
		lastCounter,
	});

	const { orderTypeValue } = useWatchPaperCounterFields(path);

	const formattedCurrencyUnit = (
		<>
			<Styled.CurrencyUnitSeparator />
			<span>{`${tCurrency(t, currency)}/${t(`price_unit_short_${unit}`, {
				lng: 'en',
			})}`}</span>
		</>
	);

	return (
		<div>
			<FieldContainer>
				<Controller
					name={toPath(path, 'price')}
					mandatory
					render={({ field, fieldState: { error } }) => (
						<MaterialNumberInput
							error={!!error || !field.value}
							data-test="priceCurrencyUnit"
							label={t('spread_price')}
							required
							allowNegative
							decimalScale={3}
							parameters={formattedCurrencyUnit}
							controlledState
							hasChanged={price.hasChanged}
							{...field}
						/>
					)}
				/>
				<Controller
					mandatory={orderTypeValue === QuoteType.Firm}
					name={toPath(path, 'volume')}
					render={({ field, fieldState: { error } }) => (
						<MaterialNumberInput
							required={orderTypeValue === QuoteType.Firm}
							error={!!error}
							data-test={field.name}
							id="volume"
							label={t('quantity')}
							fixedDecimalScale={false}
							parameters={t('price_unit_short_metric_ton')}
							controlledState
							hasChanged={volume.hasChanged}
							isAllowed={value => value.floatValue !== 0}
							{...field}
						/>
					)}
				/>
				<Controller
					name={toPath(path, 'firstLegPrice')}
					mandatory={orderTypeValue === QuoteType.Firm}
					render={({ field, fieldState: { error } }) => (
						<MaterialNumberInput
							error={!!error}
							data-test="firstLegPriceCurrencyUnit"
							id={field.name}
							label={t('first_leg_price')}
							required={orderTypeValue === QuoteType.Firm}
							allowNegative
							parameters={formattedCurrencyUnit}
							decimalScale={3}
							className="wide_price"
							controlledState
							hasChanged={firstLegPrice.hasChanged}
							{...field}
						/>
					)}
				/>
				<Controller
					name={toPath(path, 'orderType')}
					mandatory
					render={({ field, fieldState: { error } }) => (
						<MaterialSelectInput
							error={!!error}
							data-test={field.name}
							label={t('counter_type')}
							options={orderType.options}
							required
							controlledState
							hasChanged={orderType.hasChanged}
							{...field}
							onChange={value => {
								field.onChange(value);
								setValue('orderTypeChanged', true);
								if (formState.isSubmitted) {
									trigger();
								}
							}}
						/>
					)}
				/>
				{principal.visible && (
					<Controller
						fullWidth
						mandatory={principal.hasValue}
						name={toPath(path, 'principalId')}
						render={({ field, fieldState: { error } }) => (
							<Styled.WideField>
								<MaterialSelectInput
									key={`principal-dropdown${principal.ready ? '' : '-loading'}`}
									error={!!error}
									data-test={field.name}
									label={t('principal')}
									options={principal.options}
									disabled={!principal.ready}
									controlledState
									hasChanged={principal.hasChanged}
									upward
									search
									searchIcon
									{...field}
								/>
							</Styled.WideField>
						)}
					/>
				)}
			</FieldContainer>
		</div>
	);
};
