/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { QuoteType, tQuoteValueType, Currency, PriceUnit, Role } from 'src/constants/contract';
import { useWatchPaperCounterFields } from '../../../helpers/paper/useWatchPaperCounterFields';
import { useAllPaperPresets } from 'src/components/SearchHeader/useAllPaperPresets';
import { toPath } from 'src/_helpers';
import { useOnce } from 'src/_helpers/useOnce';
import { getDefaultValidity } from 'src/components/CreateOrderDrawer/paper/helpers/getDefaultValidity';
import { useQuery } from 'react-query';
import { getMyContacts } from 'src/_api';
import { sortOrder } from 'src/_helpers/sort';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/_store/selectors';

export const useCalendarSpreadFields = ({ path, lastCounter }) => {
	const { t } = useTranslation();
	const userId = useSelector(getUserId);

	const {
		setValue,
		control: { _defaultValues },
	} = useFormContext();

	const {
		currencyUnitValue,
		orderTypeValue,
		orderTypeChangedValue,
		priceValue,
		firstLegPriceValue,
		volumeValue,
		validityChangedValue,
		forceFirmValue,
		// principal id set in current counter
		principalIdValue,
		// principal id received from previous counter
		orderOwnerPrincipalIdValue,
	} = useWatchPaperCounterFields(path);

	const { data } = useAllPaperPresets();
	const presets = useMemo(() => data?.presets || [], [data?.presets]);
	const selectedProductPreset = useMemo(() => {
		return presets.find(preset => preset._key === _defaultValues.presetID);
	}, [presets, _defaultValues]);

	useEffect(() => {
		if (selectedProductPreset) {
			setValue('selectedProductPreset', selectedProductPreset);
		}
	}, [selectedProductPreset, setValue]);

	/* Order type */
	const orderTypeOptions = useMemo(
		() =>
			Object.values(QuoteType).map(value => ({
				text: tQuoteValueType(t, value),
				key: value,
				value,
			})),
		[t]
	);

	useOnce(() => {
		if (forceFirmValue) {
			setValue(toPath(path, 'orderType'), QuoteType.Firm);
		} else if (!orderTypeChangedValue && orderTypeValue === QuoteType.Firm) {
			setTimeout(() => {
				setValue(toPath(path, 'orderType'), QuoteType.Indicative);
			}, 0);
		}
	});

	/**
	 * When order type changes and the validity value wasn't changed, we set 5 minutes for Firm and 24 hours for Indicative
	 */
	useEffect(() => {
		if (validityChangedValue) {
			return;
		}

		setValue('validity', getDefaultValidity(orderTypeValue));
	}, [orderTypeValue, setValue, validityChangedValue]);

	/* Currency & Unit */
	const [currency, unit] = useMemo(() => {
		return currencyUnitValue
			? currencyUnitValue.split('/')
			: [Currency.USD, PriceUnit.MetricTon];
	}, [currencyUnitValue]);

	/** BROKER */
	const { data: allContacts = [] } = useQuery(
		[
			'my-contacts',
			{
				_limit: 1000,
				is_my_region: true,
				_sort: 'name',
				_order: sortOrder.asc,
			},
		],
		({ queryKey: [, params] }) => getMyContacts(params)
	);

	const shouldShowPrincipal =
		lastCounter.role === Role.Broker &&
		lastCounter.order_user_id === userId &&
		!lastCounter.order_owner_principal_id;

	const counterpartyId = lastCounter.user_id;

	const contactsOptions = useMemo(() => {
		return allContacts
			.filter(contact => contact.contact_user_id !== counterpartyId)
			.map(contact => ({
				text: `${contact.name} - ${contact.company_name}`,
				key: contact.contact_user_id,
				value: contact.contact_user_id,
			}));
	}, [allContacts, counterpartyId]);

	useEffect(() => {
		if (allContacts) {
			const newPrincipalObject = allContacts.find(
				contact => contact.contact_user_id === principalIdValue
			);

			if (!orderOwnerPrincipalIdValue && principalIdValue) {
				// we're setting principal for the first time
				setValue(toPath(path, 'principalObject'), newPrincipalObject);
			} else if (orderOwnerPrincipalIdValue && !principalIdValue) {
				// show existing principal
				const oldPrincipalObject = allContacts.find(
					contact => contact.contact_user_id === orderOwnerPrincipalIdValue
				);
				setValue(toPath(path, 'principalObject'), oldPrincipalObject);
				setValue(toPath(path, 'principalId'), orderOwnerPrincipalIdValue);
			} else if (orderOwnerPrincipalIdValue && principalIdValue) {
				// we're updating principal
				setValue(toPath(path, 'principalObject'), newPrincipalObject);
			} else {
				setValue(toPath(path, 'principalObject'), null);
			}
		}
	}, [allContacts, orderOwnerPrincipalIdValue, path, principalIdValue, setValue]);

	useEffect(() => {
		if (volumeValue === '') {
			setValue(toPath(path, 'volume'), null, { shouldValidate: true });
		}
	}, [path, setValue, volumeValue]);

	const previousVolume = _defaultValues.contractPricing[0].volume || null;
	const currentVolume = volumeValue || null;

	return {
		orderType: {
			options: orderTypeOptions,
			hasChanged: _defaultValues.contractPricing[0].orderType !== orderTypeValue,
		},
		price: {
			// eslint-disable-next-line eqeqeq
			hasChanged: _defaultValues.contractPricing[0].price != priceValue,
		},
		firstLegPrice: {
			// eslint-disable-next-line eqeqeq
			hasChanged: _defaultValues.contractPricing[0].firstLegPrice != firstLegPriceValue,
		},
		volume: {
			// eslint-disable-next-line eqeqeq
			hasChanged: previousVolume != currentVolume,
		},
		unit,
		currency,
		principal: {
			options: contactsOptions,
			ready: true,
			visible: shouldShowPrincipal,
			hasChanged:
				// eslint-disable-next-line eqeqeq
				_defaultValues.contractPricing[0].order_owner_principal_id != principalIdValue,
			hasValue:
				!!_defaultValues.contractPricing[0].order_owner_principal_id || !!principalIdValue,
		},
	};
};
